import {
  startOfBusinessDayISO,
  endOfBusinessDayISO,
} from "../../functions/timeHandler/bordersOfBusinessDay"

import personCreate from "../../functions/persons/personCreate"
import nodeCreate from "../../functions/nodes/nodeCreate"
import { nodesListDurationsCalc } from "../../functions/timeHandler/timeHandler2"
import addBusinessDays from "date-fns/addBusinessDays"
import isWeekend from "date-fns/isWeekend"
import { ROOT } from "../../const/globals"

export const projectInitialStateSet = () => {
  let theDay = isWeekend(new Date(Date.now()))
    ? addBusinessDays(new Date(Date.now()), 1)
    : new Date(Date.now())

  let _node1 = nodeCreate({
    pId: ROOT,
    fromWhen: startOfBusinessDayISO(theDay, "9"),
    byWhen: endOfBusinessDayISO(theDay, "17"),
  })

  let _root = nodeCreate({
    nId: ROOT,
    position: [],
    children: [_node1.nId],
    fromWhen: _node1.fromWhen,
    byWhen: _node1.byWhen,
  })

  let setup = {
    communicationTool: "",
    dataRepository: "",
    description: "",
    membersSteeringCommittee: [],
    objective: "",
    team: [],
    title: "My Project Title",
    // persons
    agileMaster: "",
    controller: "",
    customer1: "",
    customer2: "",
    manager: "",
    otherVIP: "",
    productOwner: "",
    sponsor: "",
    // statusComplete cannot be boolean in order to fill the <Select />
    statusCompleted: "In Progress",
    isStatusLocked: false,
  }

  let ratios = {
    cashBudget: "",
    cashActual: "",
    // cashProjection: 0,
    timeBudget: "",
    timeActual: "",
    // timeProjection: 0,
    // degreeOfCompletion: "",
    // degreeOfCashSpent: "",
    // degreeOfTimeSpent: "",
    // docToDobs: 0,
    // docToDots: 0,
    unresolvedIssues: 0,
    satisfactionCustomer: "",
    satisfactionTeam: "",
  }

  let params = {
    hoursPerDay: "8",
    daysPerWeek: "5",
    daysPerMonths: "22",
    dateFormat: "yyyy-MM-dd|HH",
    dayStart: "9",
    dayEnd: "17",
    isDetailsOn: true,
    isCommentVertical: true,
    isNameFirstPreferred: true,
    pUnresolvedIssues: [0, 0, 2, 4],
    pDOCThroughDOBS: [0, 90, 95, 100],
    pDOCThroughDOTS: [0, 90, 95, 100],
    pSatisfactionCustomer: [0, 50, 70, 90],
    pSatisfactionTeam: [0, 50, 70, 90],
  }

  let project = {
    dates: {
      [_root.fromWhen]: {
        dId: _root.fromWhen,
        nodes: {
          _root,
          [_node1.nId]: _node1,
        },
        rows: [ROOT, _node1.nId],
        setup,
        ratios,
        persons: {},
      },
    },
    dateInit: _root.fromWhen,
    datesStatusList: [_root.fromWhen],
    dateStatusCurrent: _root.fromWhen,
    dateStatusNext: "",
    dateStatusNextPlanned: "",
    params,
    messageFromProject: "",
    isResetForm: false,
  }

  let person = personCreate()
  project.dates[_root.fromWhen].persons[person.rId] = { ...person }

  // initial byWhenFct, span, projection, slack
  project.dates[_root.fromWhen].nodes = nodesListDurationsCalc(
    project.dates[_root.fromWhen].nodes
  )

  return project
}

let projectInitialState = {
  project: projectInitialStateSet(),
}

export default projectInitialState
