import React, { useRef } from "react"
import { connect } from "react-redux"

import {
  useDisclosure,
  Flex,
  Box,
  Switch,
  FormControl,
  FormLabel,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react"

import {
  resetProject,
  resetSheet,
  isDetailsOnUpdate,
} from "../../redux/actions/project"

const SwitchDetails = ({ params, dispatch }) => {
  //=================================================================
  const {
    isOpen: isOpenProject,
    onOpen: onOpenProject,
    onClose: onCloseProject,
  } = useDisclosure()

  const {
    isOpen: isOpenSheet,
    onOpen: onOpenSheet,
    onClose: onCloseSheet,
  } = useDisclosure()

  const cancelRefProject = useRef()
  const cancelRefSheet = useRef()

  const { isDetailsOn } = params

  //-----------------------------------------------------------------

  const onClickResetProject = () => {
    dispatch(resetProject())
    onCloseProject()
  }

  const onClickResetSheet = () => {
    dispatch(resetSheet())
    onCloseSheet()
  }

  const onChangeDetails = (e) => {
    dispatch(isDetailsOnUpdate())
  }

  //=================================================================
  return (
    <>
      <Flex justify="flex-end" w="100%">
        <Button onClick={onOpenProject}>Reset Project</Button>
        <Button onClick={onOpenSheet} ml={0}>
          Reset Sheet
        </Button>
        <Box>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="isDetailsOn" mb="0">
              Details
            </FormLabel>
            <Switch
              id="isDetailsOn"
              onChange={onChangeDetails}
              isChecked={isDetailsOn}
            />
          </FormControl>
        </Box>
      </Flex>

      <AlertDialog
        leastDestructiveRef={cancelRefProject}
        onClose={onCloseProject}
        isOpen={isOpenProject}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirm Reset Project?</AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to reset your whole project? All your
            deliverables and all persons will be deleted.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRefProject} onClick={onCloseProject}>
              Cancel
            </Button>
            <Button color="white" bg="red" ml={3} onClick={onClickResetProject}>
              Reset
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        leastDestructiveRef={cancelRefSheet}
        onClose={onCloseSheet}
        isOpen={isOpenSheet}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirm Reset Sheet?</AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to reset your sheet? Persons and general
            project parameters will be kept, but all your deliverables will be
            deleted.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRefSheet} onClick={onCloseSheet}>
              Cancel
            </Button>
            <Button color="white" bg="red" ml={3} onClick={onClickResetSheet}>
              Reset
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    params: state.project.params,
  }
}

export default connect(mapStateToProps)(SwitchDetails)
