import React, { useRef, useEffect } from "react"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { chakra, Input, Flex, Box } from "@chakra-ui/react"
import { LabelTop } from "../../componentsWithChakra"
import SelectPerson from "../SelectPerson"
import SelectMultiPersons from "../SelectMultiPersons"

import { formSetupUpdate } from "../../redux/actions/project"
import objDeepTrim from "../../functions/utils/objDeepTrim"

const FormSetup = ({ project, isResetForm = false, dispatch }) => {
  //=================================================================
  const inputRef = useRef(null)

  const { register, handleSubmit, reset } = useForm()
  const { dateStatusCurrent } = project
  const { isStatusLocked } = project.dates[dateStatusCurrent].setup

  //-----------------------------------------------------------------
  const {
    title,
    objective,
    description,
    customer1,
    customer2,
    otherVIP,
    manager,
    controller,
    productOwner,
    agileMaster,
    team,
    sponsor,
    membersSteeringCommittee,
    communicationTool,
    dataRepository,
  } = project.dates[dateStatusCurrent].setup

  //-----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset])

  //-----------------------------------------------------------------

  const onSubmit = (data) => {
    inputRef.current.focus()
    objDeepTrim(data)
    const payload = { data }
    dispatch(formSetupUpdate(payload))
  }

  const onKeyDown = (e) => {
    const keyCode = e.keyCode
    // 13 represents the Enter key
    if (keyCode === 13 && !e.shiftKey) {
      // Don't generate a new line
      e.preventDefault()
      handleSubmit(onSubmit)()
    }
  }
  //=================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Flex wrap="wrap" mb={4}>
        <Flex direction="column" w={["100%"]} pl={[0]} pr={[0]}>
          <LabelTop>Project Title</LabelTop>
          <Input
            name="title"
            defaultValue={title}
            ref={register}
            isDisabled={isStatusLocked}
          />
        </Flex>

        <Flex direction="column" w={["100%"]} pl={[0]} pr={[0]}>
          <LabelTop>Project Objective</LabelTop>
          <Input
            name="objective"
            defaultValue={objective}
            ref={register}
            isDisabled={isStatusLocked}
          />
        </Flex>

        <Flex direction="column" w={["100%"]} pl={[0]} pr={[0]}>
          <LabelTop>Project Description</LabelTop>

          <chakra.textarea
            name="description"
            defaultValue={description}
            ref={register}
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderColor="grey.400"
            borderRadius="0"
            fontSize="inherit"
            rows="3"
            onKeyDown={onKeyDown}
            bg="white"
            disabled={isStatusLocked}
          />
        </Flex>

        <Flex
          direction="column"
          w={["50%", "50%", "50%", "25%"]}
          pl={[0, 0, 0, 0]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Customer 1</LabelTop>
          <SelectPerson
            sId={"_setup"}
            name="customer1"
            value={customer1}
            isLocked={isStatusLocked}
          />
        </Flex>

        <Flex
          direction="column"
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Customer 2</LabelTop>
          <SelectPerson
            sId={"_setup"}
            name="customer2"
            value={customer2}
            isLocked={isStatusLocked}
          />
        </Flex>

        <Flex
          direction="column"
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Other VIP</LabelTop>
          <SelectPerson
            sId={"_setup"}
            name="otherVIP"
            value={otherVIP}
            isLocked={isStatusLocked}
          />
        </Flex>

        <Flex
          direction="column"
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[0, 0, 0, 0]}
        >
          <LabelTop>Project Sponsor</LabelTop>
          <SelectPerson
            sId={"_setup"}
            name="sponsor"
            value={sponsor}
            isLocked={isStatusLocked}
          />
        </Flex>

        <Flex direction="column" w={["100%"]} pl={[0]} pr={[0]}>
          <LabelTop>Project Steering Committee Members</LabelTop>
          <SelectMultiPersons
            name="membersSteeringCommittee"
            value={membersSteeringCommittee}
            isLocked={isStatusLocked}
          />
        </Flex>

        <Flex
          direction="column"
          w={["50%", "50%", "50%", "25%"]}
          pl={[0, 0, 0, 0]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Project Manager</LabelTop>
          <SelectPerson
            sId={"_setup"}
            name="manager"
            value={manager}
            isLocked={isStatusLocked}
          />
        </Flex>

        <Flex
          direction="column"
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Product Owner</LabelTop>
          <SelectPerson
            sId={"_setup"}
            name="productOwner"
            value={productOwner}
            isLocked={isStatusLocked}
          />
        </Flex>
        <Flex
          direction="column"
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Project Controller</LabelTop>
          <SelectPerson
            sId={"_setup"}
            name="controller"
            value={controller}
            isLocked={isStatusLocked}
          />
        </Flex>
        <Flex
          direction="column"
          w={["50%", "50%", "50%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[0, 0, 0, 0]}
        >
          <LabelTop>Agile Master</LabelTop>

          <SelectPerson
            sId={"_setup"}
            name="agileMaster"
            value={agileMaster}
            isLocked={isStatusLocked}
          />
        </Flex>

        <Flex direction="column" w={["100%"]} pl={[0]} pr={[0]}>
          <LabelTop>Project Team</LabelTop>
          <SelectMultiPersons
            name="team"
            value={team}
            isLocked={isStatusLocked}
          />
        </Flex>

        <Flex
          direction="column"
          w={["100%", "50%", "50%"]}
          pl={[0, 0, 0, 0]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Communication Tool</LabelTop>
          <Input
            name="communicationTool"
            defaultValue={communicationTool}
            ref={register}
            isDisabled={isStatusLocked}
          />
        </Flex>
        <Flex
          direction="column"
          w={["100%", "50%", "50%"]}
          pl={[1, 1, 1, 1]}
          pr={[0, 0, 0, 0]}
        >
          <LabelTop>Data repository</LabelTop>
          <Input
            name="dataRepository"
            defaultValue={dataRepository}
            ref={register}
            isDisabled={isStatusLocked}
          />
        </Flex>

        {isStatusLocked ? (
          <Box h="24px" w="100%"></Box>
        ) : (
          <Box w={["100%"]}>
            <chakra.button
              type="submit"
              ref={inputRef}
              textAlign="center"
              outline="none"
            >
              OK
            </chakra.button>
          </Box>
        )}
      </Flex>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
    isResetForm: state.project.isResetForm,
    params: state.project.params,
  }
}

export default connect(mapStateToProps)(FormSetup)
