import { copyDeep } from "../utils/objectCopyDeep"
import nodesList from "../nodes/nodesList"
import { ROOT } from "../../const/globals"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Removes a person rId from a given node and returns the same node
 * @param {object} node
 * @param {string} rId
 * @param {object} node
 */
export const personRemoveFromNodes = (nodes, rId) => {
  let nodesNew = copyDeep(nodes)
  const nodesAllListed = nodesList(nodesNew, ROOT, true)

  nodesAllListed.forEach((nId) => {
    personRemoveFromNode(nodes[nId], rId)
  })

  return nodesNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Removes a person rId from a given node and returns the same node
 * @param {object} node
 * @param {string} rId
 * @param {object} node
 */
export const personRemoveFromNode = (node, rId) => {
  if (rId === node.who) {
    node.who = ""
  }
  if (rId === node.toWho) {
    node.toWhom = ""
  }

  return node
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Removes a person rId from a given setup and returns the same setup
 * @param {object} node
 * @param {string} rId
 * @param {object} node
 */
export const personRemoveFromSetup = (setup, rId) => {
  if (rId === setup.agileMaster) {
    setup.agileMaster = ""
  }
  if (rId === setup.controller) {
    setup.controller = ""
  }
  if (rId === setup.customer1) {
    setup.customer1 = ""
  }
  if (rId === setup.customer2) {
    setup.customer2 = ""
  }
  if (rId === setup.customer2) {
    setup.customer2 = ""
  }
  if (rId === setup.manager) {
    setup.manager = ""
  }
  if (rId === setup.otherVIP) {
    setup.otherVIP = ""
  }
  if (rId === setup.productOwner) {
    setup.productOwner = ""
  }
  if (rId === setup.sponsor) {
    setup.sponsor = ""
  }

  setup.team = setup.team.filter((member) => member.value !== rId)

  setup.membersSteeringCommittee = setup.membersSteeringCommittee.filter(
    (member) => member.value !== rId
  )

  return setup
}
