import React from "react"
import { connect } from "react-redux"
import { chakra, Divider, Flex, Box, Text, Link } from "@chakra-ui/react"
import { Link as LinkRouter } from "react-router-dom"
import { VERSION_APP, VERSION_API } from "../../const/versions"
import { META_URL_SITE } from "../../const/urlCRA"
import { addPoint, shortHashGen } from "../../functions/utils/hashes"
import { isDebugOnUpdate } from "../../redux/actions/app"
import Bowser from "bowser"

import preval from "preval.macro"

const browser = Bowser.parse(window.navigator.userAgent)

let date = preval`
const vHoursDiff = process.env.NODE_ENV === "production" ? 1 : 0
let newDate = new Date()
newDate.setTime(newDate.getTime() + (vHoursDiff*60*60*1000));
const dateStr = newDate.getFullYear() + "-" +
    ("0"+ (newDate.getMonth()+1)).slice(-2) + "-" +
    ("0" + newDate.getDate()).slice(-2)  + "|" +
    ("0" + newDate.getHours()).slice(-2) + ":" +
    ("0" + newDate.getMinutes()).slice(-2);
module.exports=dateStr`

const PageFooter = ({ project, app, dispatch }) => {
  // ==========================================================================

  const onClickDebug = () => {
    dispatch(isDebugOnUpdate())
  }
  // ==========================================================================
  return (
    <>
      <Divider mt={16} />
      <Flex justify="space-between" wrap="wrap">
        <Flex direction="column">
          <Text>© Prof. Dr. Ulrich Anders</Text>
          <Text>prof.anders@online.de</Text>
          <Link href={META_URL_SITE + "/"} isExternal>
            {META_URL_SITE}
          </Link>
          <Link
            href="https://creativecommons.org/licenses/by-nd/4.0/"
            isExternal
          >
            License: CC BY-ND 4.0
          </Link>
          <Link as={LinkRouter} to="/factory-reset">
            Factory Reset
          </Link>
        </Flex>

        <Box>
          <Text>
            Params Hash · {addPoint(shortHashGen(JSON.stringify({ project })))}
          </Text>
          <Text>
            Vers
            <chakra.span onClick={onClickDebug} cursor="pointer">
              i
            </chakra.span>
            on: {VERSION_APP} | API {VERSION_API}
          </Text>
          <Text>Last change: {date}</Text>
          <Text>
            Browser: {browser.browser.name} {browser.browser.version}
          </Text>
          <Flex>
            <Link href={META_URL_SITE + "/imprint"} isExternal>
              Imprint
            </Link>
            <Text ml={2}>·</Text>
            <Link href={META_URL_SITE + "/data-protection"} ml={2}>
              Data Protection
            </Link>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
    app: state.app,
  }
}

export default connect(mapStateToProps)(PageFooter)
