/**
## MoveIn

| Action | Before   | After    | Comment |
| ------ | -------- | -------- | ------- |
|        | "\_root" | "\_root" |         |
|        | "1"      | "1"      |         |
|        |          | "2"      | New     |
| -->    | "2"      | "2.1"    |         |

| Action | Before   | After    | Comment |
| ------ | -------- | -------- | ------- |
|        | "\_root" | "\_root" |         |
|        | "1"      | "1"      |         |
|        | "2"      | "2"      |         |
|        | "2.1"    | "2.1"    |         |
| -->    | "3"      | "2.2"    |         |

| Action | Before   | After    | Comment |
| ------ | -------- | -------- | ------- |
|        | "\_root" | "\_root" |         |
|        | "1"      | "1"      |         |
|        | "2"      | "2"      |         |
|        | "2.1"    | "2.1"    |         |
|        | "2.1.1"  | "2.1.1"  |         |
|        | "2.1.2"  | "2.1.2"  |         |
| -->    | "3"      | "2.2"    |         |

*/

import nodeCreate from "./nodeCreate"
import { copyDeep } from "../utils/objectCopyDeep"
import { ROOT } from "../../const/globals"
import {
  nodeCanBecomeAggregationCalc,
  nodeIsEmptyCalc,
} from "./nodeIsEmptyCalc"

/**
 * (c) Jasper Anders
 *
 * Creates nodesNew after nId has been moved in in the nodes tree
 * @param {object} nodes
 * @param {object} node
 * @param {array} listNodesVisible - list of nodes
 */
export function moveIn(nodes, nId, listNodesVisible) {
  let node = nodes[nId]
  const pId = nodes[nId].pId
  const parent = nodes[pId]
  const indexListNodesVisible = listNodesVisible.indexOf(nId)
  const neighborTop = nodes[listNodesVisible[indexListNodesVisible - 1]]
  let nodesNew = copyDeep(nodes)

  // if neighborTop is on lower level
  if (
    indexListNodesVisible > 1 &&
    node.position.length < neighborTop.position.length
  ) {
    // find index nId in children of parent
    const indexNIdChildOfPId = parent.children.indexOf(nId)
    // the parent of the neighborTop before becomes the new parent
    const parentNew = nodes[parent.children[indexNIdChildOfPId - 1]]
    // set new parent of node
    nodesNew[nId].pId = parentNew.nId
    // set node as new child of new parent
    nodesNew[parentNew.nId].children.push(nId)
    // remove node from children of old parent
    nodesNew[pId].children.splice(indexNIdChildOfPId, 1)
  }
  // else if neighborTop is on same level but empty.
  else if (
    node.position.length === neighborTop.position.length &&
    nodeCanBecomeAggregationCalc(neighborTop) &&
    nodeIsEmptyCalc(node)
  ) {
    // find index nId in children of parent
    const indexNIdChildOfPId = parent.children.indexOf(nId)
    // the one before becomes the new parent
    const parentNew = neighborTop
    // set new parent of node
    nodesNew[nId].pId = parentNew.nId
    // set node as new child of new parent
    nodesNew[parentNew.nId].children.push(nId)
    // remove node from children of old parent
    nodesNew[pId].children.splice(indexNIdChildOfPId, 1)
  } else {
    // create new parent node
    const parentNew = nodeCreate({
      nId: null,
      pId,
      children: [nId],
      fromWhen: nodesNew[nId].fromWhen,
      byWhen: nodesNew[nId].byWhen,
      byWhenFct: nodesNew[nId].byWhenFct,
    })
    parentNew.deliversWhat = nodesNew[nId].deliversWhat + " ++"
    parentNew.who = nodesNew[nId].who
    parentNew.toWhom = nodesNew[nId].toWhom

    // create nodesNew
    nodesNew = {
      ...nodesNew,
      [parentNew.nId]: { ...parentNew },
      [nId]: { ...node, pId: parentNew.nId },
    }
    // change old parent node
    const indexNIdChildOfPId = parent.children.indexOf(nId)
    nodesNew[pId].children.splice(indexNIdChildOfPId, 1, parentNew.nId)
  }
  return nodesNew
}

/**
 *

## MoveOut

| Action | Before   | After    | Comment   |
| ------ | -------- | -------- | --------- |
| <--    | "\_root" | "\_root" | no effect |
| <--    | "1"      | "1"      | no effect |
|        | "2"      | "2"      |           |
|        | "3"      | "3"      |           |

| Action | Before   | After    | Comment |
| ------ | -------- | -------- | ------- |
|        | "\_root" | "\_root" |         |
|        | "1"      | "1"      |         |
|        | "2"      |          | Deleted |
| <--    | "2.1"    | "2"      |         |
|        | "3"      | "3"      |         |

| Action | Before   | After    | Comment   |
| ------ | -------- | -------- | --------- |
|        | "\_root" | "\_root" |           |
|        | "1"      | "1"      |           |
|        | "2"      | "2"      |           |
|        | "2.1"    | "2.1"    |           |
| <--    | "2.2"    |          |           |
|        | "2.3"    | "2.2"    |           |
|        |          | "3"      | was "2.2" |
|        | "3"      | "4"      |           |
*/

/**
 * (c) Jasper Anders
 *
 * Creates nodesNew after nId has been moved out in the nodes tree
 * @param {object} nodes - nodes tree
 * @param {string} nId - nId of node
 * @return {object} nodesNew - resulting tree after move out
 */
export function moveOut(nodes, nId) {
  let node = nodes[nId]
  let pId = node.pId
  let nodesNew = copyDeep(nodes)
  // only move out if at least on level 2
  if (node.position.length > 1) {
    // find grand parent gpId, move up two parents
    const gpId = nodes[pId].pId
    // set new parent of node
    nodesNew[nId].pId = gpId
    // set node as new child of grandparent after index of parent
    const indexPIdChildOfGpId = nodes[gpId].children.indexOf(pId)
    nodesNew[gpId].children.splice(indexPIdChildOfGpId + 1, 0, nId)

    // keep parent node because child is empty
    if (nodesNew[pId].children.length === 1 && nodeIsEmptyCalc(node)) {
      // remove children from parent node and keep the rest
      nodesNew[pId].children = []
      // previous grand parent becomes the new parent
      nodesNew[nId].pId = gpId
    }
    // remove pId node if node is only child of parent
    else if (nodesNew[pId].children.length === 1) {
      // remove pId from children of gpId
      const indexPIdChildOfGpId = nodes[gpId].children.indexOf(pId)
      nodesNew[gpId].children.splice(indexPIdChildOfGpId, 1)
      // remove pId node
      delete nodesNew[pId]
    } else {
      const indexNIdChildOfPId = nodes[pId].children.indexOf(nId)
      nodesNew[pId].children.splice(indexNIdChildOfPId, 1)
    }
  }
  return nodesNew
}

/**

## MoveUp

Situation 1
| Action | Before   | After    | Comment   |
| ------ | -------- | -------- | --------- |
| ^      | "\_root" | "\_root" | no effect |
| ^      | "1"      | "1"      | no effect |
|        | "2"      | "2"      |           |
|        | "3"      | "3"      |           |

Situation 2
| Action | Before   | After    | Comment |
| ------ | -------- | -------- | ------- |
|        | "\_root" | "\_root" |         |
|        | "1"      | "1"      | was "2" |
| ^      | "2"      | "2"      | was "1" |
|        | "3"      | "3"      |         |

## MoveDown
depends on fold

Situation 1
| Action | Before   | After    | Comment   |
| ------ | -------- | -------- | --------- |
| v      | "\_root" | "\_root" | no effect |
|        | "1"      | "1"      |           |
|        | "2"      | "2"      |           |
|        | "3"      | "3"      |           |

| Action | Before   | After    | Comment   |
| ------ | -------- | -------- | --------- |
|        | "\_root" | "\_root" |           |
|        | "1"      | "1"      |           |
|        | "2"      | "2"      |           |
| v      | "3"      | "3"      | no effect |

Situation 2
| Action | Before   | After    | Comment |
| ------ | -------- | -------- | ------- |
|        | "\_root" | "\_root" |         |
| v      | "1"      | "1"      | was "2" |
|        | "2"      | "2"      | was "1" |
|        | "3"      | "3"      |         |
*/
/**
 *
 * (c) Jasper Anders
 *
 * Moves a node up or down in its current level.
 * @param {object} nodes
 * @param {string} nId
 * @param {array} listNodesVisible
 * @param {boolean} isMoveUp
 * @return {object} nodesNew
 */
export function moveUpDown(nodes, nId, listNodesVisible, isMoveUp) {
  const pId = nodes[nId].pId
  let nodesNew = copyDeep(nodes)
  const moveValue = isMoveUp ? -1 : 1
  const parent = nodes[pId]
  const indexListNodesVisible = listNodesVisible.indexOf(nId)
  // situation 1
  if (
    (indexListNodesVisible === 1 && isMoveUp) ||
    (indexListNodesVisible + 1 === listNodesVisible.length && !isMoveUp) ||
    nId === ROOT
  ) {
    // do nothing
    return nodesNew
  }
  // situation 2
  // remove node in the parents' child array
  const indexNIdChildOfPId = parent.children.indexOf(nId)
  nodesNew[pId].children.splice(indexNIdChildOfPId, 1)
  // place it inside the new position either + or - 1 of previous position
  nodesNew[pId].children.splice(indexNIdChildOfPId + moveValue, 0, nId)
  // parentId stays the same. There is no need to change it.
  return nodesNew
}
