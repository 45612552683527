import React, { useRef, useEffect } from "react"
import { useForm } from "react-hook-form"
import { chakra, Input, Flex, Box, Tooltip } from "@chakra-ui/react"
import { formRatiosUpdate } from "../../redux/actions/project"
import { LabelTop } from "../../componentsWithChakra"

import {
  NUMBER_MESSAGE,
  NUMBER_REGEX,
  PERCENT_MESSAGE,
  PERCENT_REGEX,
} from "../../const/messages"

import objDeepTrim from "../../functions/utils/objDeepTrim"

const FormRatios = ({ project, settings, isResetForm = false, dispatch }) => {
  //=================================================================
  const { currencySymbol } = settings
  const { dateStatusCurrent } = project
  const { isStatusLocked } = project.dates[dateStatusCurrent].setup

  const inputRef = useRef(null)

  const { register, handleSubmit, reset, errors } = useForm()

  const {
    satisfactionCustomer,
    satisfactionTeam,
    cashBudget,
    cashActual,
  } = project.dates[dateStatusCurrent].ratios

  //-----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset])

  //-----------------------------------------------------------------
  const onSubmit = (data) => {
    inputRef.current.focus()
    objDeepTrim(data)
    const payload = { data }
    dispatch(formRatiosUpdate(payload))
  }

  //=================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Flex w="100%" justify="flex-end">
        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[0, 0, 0, 0]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Cash Budget {currencySymbol}</LabelTop>
          <Tooltip
            isOpen={errors.cashBudget}
            label={errors.cashBudget ? errors.cashBudget.message : ""}
            placement="top"
            hasArrow
            bg="red.500"
          >
            <Input
              name="cashBudget"
              defaultValue={cashBudget}
              ref={register({
                pattern: {
                  value: NUMBER_REGEX,
                  message: NUMBER_MESSAGE,
                },
              })}
              isDisabled={isStatusLocked}
            />
          </Tooltip>
        </Box>

        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Cash Actual {currencySymbol}</LabelTop>

          <Input
            name="cashActual"
            defaultValue={cashActual}
            ref={register}
            isDisabled={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <LabelTop>Customer Satisfaction %</LabelTop>
          <Tooltip
            isOpen={errors.satisfactionCustomer}
            label={
              errors.satisfactionCustomer
                ? errors.satisfactionCustomer.message
                : ""
            }
            placement="top"
            hasArrow
            bg="red.500"
          >
            <Input
              name="satisfactionCustomer"
              ref={register({
                pattern: {
                  value: PERCENT_REGEX,
                  message: PERCENT_MESSAGE,
                },
              })}
              defaultValue={satisfactionCustomer}
              isDisabled={isStatusLocked}
            />
          </Tooltip>
        </Box>

        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[0, 0, 0, 0]}
        >
          <LabelTop>Team Satisfaction %</LabelTop>
          <Tooltip
            isOpen={errors.satisfactionTeam}
            label={
              errors.satisfactionTeam ? errors.satisfactionTeam.message : ""
            }
            placement="top"
            hasArrow
            bg="red.500"
          >
            <Input
              name="satisfactionTeam"
              ref={register({
                pattern: {
                  value: PERCENT_REGEX,
                  message: PERCENT_MESSAGE,
                },
              })}
              defaultValue={satisfactionTeam}
              isDisabled={isStatusLocked}
            />
          </Tooltip>
        </Box>
      </Flex>
      {isStatusLocked ? (
        <Box w="100%" h="24px" mb={4}></Box>
      ) : (
        <Box w="100%" order={[10]} mb={4}>
          <chakra.button type="submit" ref={inputRef} outline="none">
            OK
          </chakra.button>
        </Box>
      )}
    </form>
  )
}

export default FormRatios
