/**
 * (c) Prof. Dr. Ulrich Anders
 *
 *
 * Transforms an array of select objects, e.g. {value: "rodk", label: "Ulrich A"}, into a sorted string
 * @param {object} persons
 * @param {array} selected
 * @returns {string} selectedStr
 */
const selectedArrayToString = (persons, selected) => {
  let selectedStr = ""

  if (selected.length === 0) {
    return selectedStr
  } else {
    selectedStr = selected
  }

  selectedStr = selectedStr.slice().sort((a, b) => {
    return persons[a.value].nameDisplayShort > persons[b.value].nameDisplayShort
      ? 1
      : persons[b.value].nameDisplayShort > persons[a.value].nameDisplayShort
      ? -1
      : 0
  })

  selectedStr = selectedStr
    .map((person) => persons[person.value].nameDisplayLong)
    .join(", ")

  return selectedStr
}

export default selectedArrayToString
