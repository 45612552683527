import nanoId from "../utils/nanoid"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Creates an empty node.
 * @param {string} nId - node Id, if not set it gets a nanoId()
 * @param {string} pId - parent Id
 * @param {array} position - parentId of nId
 * @param {array} children - children of nId
 * @returns {object} nodeNew - node created
 */
const nodeCreate = ({
  nId,
  pId = "",
  position = [1],
  children = [],
  fromWhen = "",
  byWhen = "",
  byWhenFct = { days: 0, hours: 0 },
}) => {
  let nodeNew = {
    nId: nId ? nId : nanoId(),
    // meta
    pId, //parentId
    children,
    position,
    // core
    who: "",
    deliversWhat: "",
    fromWhen: fromWhen,
    byWhen: byWhen,
    byWhenLatest: "",
    byWhenFct: byWhenFct,
    isByWhenPinned: false,
    toWhom: "",
    precedents: [],
    precedentsStr: "",
    dependents: [],
    // eval
    degree: 0,
    deadline: 0,
    forecast: 0,
    quality: 0,
    comment: "",
    // set + calc
    spent: { days: 0, hours: 0 },
    projection: { days: 0, hours: 0 },
    span: { days: 0, hours: 0 },
    slack: { days: 0, hours: 0 },
    // control
    isUnresolved: false,
    isSelected: false,
    isFolded: false,
    isIgnored: false,
    isLocked: false,
    isHighlighted: false,
  }
  return nodeNew
}

export default nodeCreate

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Compares a node with the same nId over two different statusDates
 * @param {*} param0
 * @returns
 */
export function nodeDiffCalc({ project, dId1, dId2, nId }) {
  if (dId2 === undefined) {
    dId2 = dId1
  }

  // one node may be undefined

  let node1 = project.dates[dId1].nodes[nId]
  let node2 = project.dates[dId2].nodes[nId]

  let nodeDiff = { nId }

  if (node1 === undefined || node2 === undefined) {
    return nodeDiff
  }

  if (node2.who !== node1.who) nodeDiff.who = node2.who

  if (node2.deliversWhat !== node1.deliversWhat)
    nodeDiff.deliversWhat = node2.deliversWhat

  if (node2.toWhom !== node1.toWhom) nodeDiff.toWhom = node2.toWhom

  if (node2.byWhen !== node1.byWhen) nodeDiff.byWhen = node2.byWhen

  if (node2.isByWhenPinned !== node1.isByWhenPinned)
    nodeDiff.isByWhenPinned = node2.isByWhenPinned

  if (node2.fromWhen !== node1.fromWhen) nodeDiff.fromWhen = node2.fromWhen

  if (node2.deadline !== node1.deadline) nodeDiff.deadline = node2.deadline

  if (node2.forecast !== node1.forecast) nodeDiff.forecast = node2.forecast

  if (node2.quality !== node1.quality) nodeDiff.quality = node2.quality

  if (node2.precedentsStr !== node1.precedentsStr)
    nodeDiff.precedentsStr = node2.precedentsStr

  if (node2.comment !== node1.comment) nodeDiff.comment = node2.comment

  if (node2.isUnresolved !== node1.isUnresolved)
    nodeDiff.isUnresolved = node2.isUnresolved

  if (node2.isLocked !== node1.isLocked) nodeDiff.isLocked = node2.isLocked

  if (node2.isIgnored !== node1.isIgnored) nodeDiff.isIgnored = node2.isIgnored

  if (node2.isFolded !== node1.isFolded) nodeDiff.isFolded = node2.isFolded

  if (node2.isSelected !== node1.isSelected)
    nodeDiff.isSelected = node2.isSelected

  //position

  return nodeDiff
}
