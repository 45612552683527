import { DAY_END, DAY_START, ROOT } from "../../const/globals"
import parseISO from "date-fns/parseISO"
import getHours from "date-fns/getHours"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import { copyDeep } from "../utils/objectCopyDeep"
import nodesList from "../nodes/nodesList"
import { durToHours, hoursToDur } from "./durations"
import { spanCalcFromByWhenFct } from "./timeHandler"
import { spentExtrapolate } from "../utils/mathHelper"

/**
 * (c) Ulrich Anders
 *
 * Calculates the byWhenFct for a node and mutates the node.
 * It returns the node itself.
 * @param {object} node
 * @param {string} nId
 * @param {object} node
 */
export function nodeByWhenFctCalc(
  node,
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  const { byWhen, fromWhen } = node
  const byWhenDate = parseISO(byWhen)
  const fromWhenDate = parseISO(fromWhen)

  let businessDays = differenceInBusinessDays(byWhenDate, fromWhenDate)

  const hoursDiff = getHours(byWhenDate) - getHours(fromWhenDate)
  let hours = hoursDiff

  if (getHours(fromWhenDate) + hoursDiff > businessDayEnd) {
    const remainHours = hours - businessDayEnd
    businessDays += 1
    hours = remainHours
  } else if (getHours(fromWhenDate) + hoursDiff <= businessDayStart) {
    const remainHours = businessDayEnd - (businessDayStart - hours)
    businessDays -= 1
    hours = remainHours
  }

  node.byWhenFct = {
    days: businessDays ? businessDays : 0,
    hours: hours ? hours : 0,
  }
  return node
}

/**
 * (c) Ulrich Anders
 * TODO: NEEDED?
 *
 * Calculates for all nodes beginning at a nId the fromWhen property
 * depending on the byWhen prop of its dependencies
 * @param {object} nodes
 * @param {string} nId
 */
export function nodesListByWhenFctCalc(nodes, nId = ROOT) {
  let nodesNew = copyDeep(nodes)
  const nodesToCalculate = nodesList(nodes, nId, true)

  nodesToCalculate.forEach((nId) => {
    nodeByWhenFctCalc(nodesNew[nId])
  })

  return nodesNew
}

/**
 * (c) Ulrich Anders
 *
 * Calculates the span as a result of the byWhenFct
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function nodeSpanCalc(node) {
  node.span = spanCalcFromByWhenFct(node.fromWhen, node.byWhenFct)
  return node
}

/**
 * (c) Ulrich Anders
 *
 * Calculates the slack. I.d. slack = span - projection
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function nodeSlackCalc(node) {
  // slack is:
  const spanHours = durToHours(node.span)
  const projectionHours = durToHours(node.projection)
  node.slack = hoursToDur(spanHours - projectionHours)
  return node
}

/**
 * (c) Ulrich Anders
 *
 * Calculates the projection as an extrapolation of spent
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */

export function nodeProjectionCalc(node) {
  node.projection = spentExtrapolate(node.spent, node.degree)
  if (node.projection.days === 0 && node.projection.hours === 0) {
    node.projection = node.span
  }
  if (node.isIgnored) {
    node.projection = node.spent
  }

  return node
}

/**
 * (c) Ulrich Anders
 *
 * Calculates for a node list beginning at a nId
 * byWhenFct, span, projection, and slack
 * @param {object} nodes
 * @param {string} nId
 */
export function nodesListDurationsCalc(nodes, nId = ROOT) {
  let nodesNew = copyDeep(nodes)
  const nodesToCalculate = nodesList(nodes, nId, true)

  nodesToCalculate.forEach((nId) => {
    nodeByWhenFctCalc(nodesNew[nId])
    nodeSpanCalc(nodesNew[nId])
    nodeProjectionCalc(nodesNew[nId])
    nodeSlackCalc(nodesNew[nId])
  })

  return nodesNew
}
