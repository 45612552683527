import React from "react"
import { connect } from "react-redux"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import min from "date-fns/min"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import {
  Heading,
  Text,
  Flex,
  Box,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react"
import { LabelTop } from "../../componentsWithChakra"
import TrafficLight from "../TrafficLight"
import GanttChart from "../GanttChart"
import GanttChartResize from "../GanttChartResize"
import colorCodeCalc from "../../functions/eval/colorCodeCalc"
import parseInt0 from "../../functions/utils/parseInt0"
import selectedArrayToString from "../../functions/helpers/selectedArrayToString"
import { accordionStateUpdate } from "../../redux/actions/settings"
import { ROOT } from "../../const/globals"

const SheetSummary = ({ project, settings, isResetForm = false, dispatch }) => {
  //=================================================================

  const onChange = (accordionState) => {
    const payload = { accordionState }
    dispatch(accordionStateUpdate(payload))
  }

  //-----------------------------------------------------------------

  const { accordionState, currencySymbol } = settings

  const { dateStatusCurrent, dateStatusNextPlanned, params } = project
  const { persons, nodes } = project.dates[dateStatusCurrent]

  const {
    title,
    objective,
    description,
    communicationTool,
    dataRepository,
    customer1,
    customer2,
    otherVIP,
    manager,
    controller,
    productOwner,
    agileMaster,
    team,
    sponsor,
    membersSteeringCommittee,
  } = project.dates[dateStatusCurrent].setup

  let {
    cashBudget,
    cashActual,
    unresolvedIssues,
    satisfactionCustomer,
    satisfactionTeam,
  } = project.dates[dateStatusCurrent].ratios

  if (unresolvedIssues === undefined) {
    unresolvedIssues = 0
  }

  const dateStartDate = parseISO(
    project.dates[dateStatusCurrent].nodes[ROOT].fromWhen
  )

  const dateEndDate = parseISO(
    project.dates[dateStatusCurrent].nodes[ROOT].byWhen
  )

  const dateStatusCurrentDate = parseISO(dateStatusCurrent)

  const dateStart = format(dateStartDate, "yyyy-MM-dd")

  const dateEnd = format(dateEndDate, "yyyy-MM-dd")

  const degreeOfCompletion = nodes[ROOT].degree

  const timeBudget = differenceInBusinessDays(dateEndDate, dateStartDate) + 1

  const timeActual =
    differenceInBusinessDays(
      min([dateStatusCurrentDate, dateEndDate]),
      dateStartDate
    ) + 1

  cashBudget = parseInt0(cashBudget)
  cashActual = parseInt0(cashActual)
  satisfactionCustomer = parseInt0(satisfactionCustomer)
  satisfactionTeam = parseInt0(satisfactionTeam)

  const {
    pUnresolvedIssues,
    pDOCThroughDOBS,
    pDOCThroughDOTS,
    pSatisfactionCustomer,
    pSatisfactionTeam,
  } = params

  const degreeOfCashSpent =
    cashBudget === 0 ? 0 : Math.round((cashActual / cashBudget) * 100)

  const degreeOfTimeSpent =
    timeBudget === 0 ? 0 : Math.round((timeActual / timeBudget) * 100)

  const cashProjection =
    degreeOfCompletion === 0
      ? 0
      : Math.round((cashActual / degreeOfCompletion) * 100)

  const timeProjection =
    degreeOfCompletion === 0
      ? 0
      : Math.round((timeActual / degreeOfCompletion) * 100)

  const DOCThroughDOBS =
    degreeOfCashSpent === 0
      ? 0
      : Math.round((degreeOfCompletion / degreeOfCashSpent) * 100)

  const DOCThroughDOTS =
    degreeOfTimeSpent === 0
      ? 0
      : Math.round((degreeOfCompletion / degreeOfTimeSpent) * 100)

  const isInverse = true

  //=================================================================
  return (
    <>
      <Heading as="h2" textStyle="h3">
        {title}
      </Heading>
      <Accordion
        onChange={onChange}
        index={accordionState}
        allowMultiple
        reduceMotion={true}
      >
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Objective & Description
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box>
              <LabelTop>Objective</LabelTop>
              <Text>{objective}</Text>
            </Box>
            {description !== "" && (
              <Box>
                <LabelTop>Description</LabelTop>
                <Text>{description}</Text>
              </Box>
            )}
            <Flex>
              {communicationTool !== "" && (
                <Box w={["50%"]} pr={[1]}>
                  <LabelTop>Communication Tool</LabelTop>
                  <Text>{communicationTool}</Text>
                </Box>
              )}
              {dataRepository !== "" && (
                <Box w={["50%"]} pl={[1]}>
                  <LabelTop>Data Repository</LabelTop>
                  <Text>{dataRepository}</Text>
                </Box>
              )}
            </Flex>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Customer(s), Persons & Team
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex>
              <Box w={["50%"]} pl={[0]} pr={[1]}>
                {customer1 !== "" && (
                  <>
                    <LabelTop>Customer 1</LabelTop>
                    <Text>{persons[customer1].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box w={["50%"]} pl={[1]} pr={[1]}>
                {customer2 !== "" && (
                  <>
                    <LabelTop>Customer 2</LabelTop>
                    <Text>{persons[customer2].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box w={["50%"]} pl={[1]} pr={[1]}>
                {otherVIP !== "" && (
                  <>
                    <LabelTop>Other VIP</LabelTop>
                    <Text>{persons[otherVIP].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box w={["50%"]} pl={[1]} pr={[0]}>
                {sponsor !== "" && (
                  <>
                    <LabelTop>Project Sponsor</LabelTop>
                    <Text>{persons[sponsor].nameDisplayLong}</Text>
                  </>
                )}
              </Box>
            </Flex>

            {membersSteeringCommittee.length > 0 && (
              <Box>
                <LabelTop>Members Project Steering Committee</LabelTop>
                <Text>
                  {selectedArrayToString(persons, membersSteeringCommittee)}
                </Text>
              </Box>
            )}

            <Flex>
              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[0, 0, 0, 0]}
                pr={[1, 1, 1, 1]}
              >
                {manager !== "" && (
                  <>
                    <LabelTop>Project Manager</LabelTop>
                    <Text>{persons[manager].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[1, 1, 1, 1]}
              >
                {productOwner !== "" && (
                  <>
                    <LabelTop>Product Owner</LabelTop>
                    <Text>{persons[productOwner].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[1, 1, 1, 1]}
              >
                {controller !== "" && (
                  <>
                    <LabelTop>Project Controller</LabelTop>
                    <Text>{persons[controller].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[0, 0, 0, 0]}
              >
                {agileMaster !== "" && (
                  <>
                    <LabelTop>Agile Master</LabelTop>
                    <Text>{persons[agileMaster].nameDisplayLong}</Text>
                  </>
                )}
              </Box>
            </Flex>

            {team.length > 0 && (
              <Box w={["100%"]} pl={[0]} pr={[0]}>
                <LabelTop>Project Team</LabelTop>
                <Text>{selectedArrayToString(persons, team)}</Text>
              </Box>
            )}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Dates
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex>
              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[0, 0, 0, 0]}
                pr={[1, 1, 1, 1]}
              >
                <Tooltip
                  label="The Start Date of the project. It can be entered in the Setup tab."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Start Date</LabelTop>
                </Tooltip>
                <Text>{dateStart}</Text>
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[0, 0, 1, 1]}
              >
                <Tooltip
                  label="The End Date of the project. It results from the project plan."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>End Date</LabelTop>
                </Tooltip>
                <Text>{dateEnd}</Text>
              </Box>
              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[0, 0, 1, 1]}
              >
                <Tooltip
                  label="All tasks will be evaluated on the Current Status Date. Automatic deadline evaluation takes place for all tasks that have a delivery date BEFORE the Current Status Date."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Current Status Date</LabelTop>
                </Tooltip>

                <Text>{format(parseISO(dateStatusCurrent), "yyyy-MM-dd")}</Text>
              </Box>
              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[0, 0, 1, 1]}
              >
                <Tooltip
                  label="Forthcoming status date, if already planned and entered in Setup."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Planned Next Status Date</LabelTop>
                </Tooltip>
                <Text>
                  {dateStatusNextPlanned === ""
                    ? ""
                    : format(parseISO(dateStatusNextPlanned), "yyyy-MM-dd")}
                </Text>
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Important Ratios
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex>
              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="CASH BUDGET: The cash assigned to the project from Project Start to Project End."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Cash Budget {currencySymbol}</LabelTop>
                </Tooltip>
                <Text>{cashBudget}</Text>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="CASH ACTUAL: The cash already spent until the Current Status Date."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Cash Actual {currencySymbol}</LabelTop>
                </Tooltip>
                <Text>{cashActual}</Text>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="CASH PROJECTION: The cash amount that will likely be needed. It is computed as Cash Projection = Cash Actual / Degree of Completion."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Cash Projection {currencySymbol}</LabelTop>
                </Tooltip>
                <Text>{cashProjection}</Text>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="DEGREE OF CASH SPENT %: Cash Actual / Cash Budget"
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Degree of Cash Spent %</LabelTop>
                </Tooltip>
                <Text>{degreeOfCashSpent}</Text>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="DEGREE OF COMPLETION %: Degree by which the project is considered completed. The number is taken from the top row in the sheet below."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Degree of Completion %</LabelTop>
                </Tooltip>
                <Text>{degreeOfCompletion}</Text>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="DOC/DOBS %: Degree of Completion / Degree of Budget Spent * 100."
                  placement="top-end"
                  hasArrow
                >
                  <LabelTop>DOC/DOBS %</LabelTop>
                </Tooltip>

                <Flex justify="flex-end" align="center">
                  <Text mr={2}>{DOCThroughDOBS}</Text>
                  <TrafficLight
                    colorCode={colorCodeCalc(DOCThroughDOBS, pDOCThroughDOBS)}
                    position="relative"
                    top="-1px"
                  />
                </Flex>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="SATISFACTION CUSTOMER %: Needs to be researched outside of this app."
                  placement="top-end"
                  hasArrow
                >
                  <LabelTop>Satisf. Customer %</LabelTop>
                </Tooltip>
                <Flex justify="flex-end" align="center">
                  <Text mr={2}>{satisfactionCustomer}</Text>
                  <TrafficLight
                    colorCode={colorCodeCalc(
                      satisfactionCustomer,
                      pSatisfactionCustomer
                    )}
                    position="relative"
                    top="-1px"
                  />
                </Flex>
              </Box>
            </Flex>

            <Flex>
              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="TIME BUDGET: Business days between the Project Start Date and the Project End Date."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Time Budget d</LabelTop>
                </Tooltip>
                <Text>{timeBudget}</Text>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="TIME ACTUAL: Business days between the Project Start Date and Current Status Date."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Time Actual d</LabelTop>
                </Tooltip>
                <Text>{timeActual}</Text>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="TIME PROJECTION: The time in business days, that will likely be needed. It is computed as Time Projection = Time Actual / Degree of Completion."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Time Projection d</LabelTop>
                </Tooltip>
                <Text>{timeProjection}</Text>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="DEGREE OF TIME SPENT: Time Actual / Time Budget."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Degree of Time Spent %</LabelTop>
                </Tooltip>
                <Text>{degreeOfTimeSpent}</Text>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="UNRESOLVED ISSUES: Number (Π for pieces) of deliverables in sheet marked as unresolved."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Unresolved Issues Π</LabelTop>
                </Tooltip>
                <Flex justify="flex-end" align="center">
                  <Text mr={2}>{unresolvedIssues}</Text>
                  <TrafficLight
                    colorCode={colorCodeCalc(
                      unresolvedIssues,
                      pUnresolvedIssues,
                      isInverse
                    )}
                    position="relative"
                    top="-1px"
                  />
                </Flex>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="DOC/DOTS %: Degree of Completion / Degree of Time Spent * 100"
                  placement="top-end"
                  hasArrow
                >
                  <LabelTop>DOC/DOTS %</LabelTop>
                </Tooltip>
                <Flex justify="flex-end" align="center">
                  <Text mr={2}>{DOCThroughDOTS}</Text>
                  <TrafficLight
                    colorCode={colorCodeCalc(DOCThroughDOTS, pDOCThroughDOTS)}
                    position="relative"
                    top="-1px"
                  />
                </Flex>
              </Box>

              <Box w={["14%"]} textAlign="right">
                <Tooltip
                  label="SATISFACTION TEAM %: Needs to be researched outside of this app."
                  placement="top-end"
                  hasArrow
                >
                  <LabelTop>Satisfaction Team %</LabelTop>
                </Tooltip>
                <Flex justify="flex-end" align="center">
                  <Text mr={2}>{satisfactionTeam}</Text>
                  <TrafficLight
                    colorCode={colorCodeCalc(
                      satisfactionTeam,
                      pSatisfactionTeam
                    )}
                    position="relative"
                    top="-1px"
                  />
                </Flex>
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton as={"a"}>
            <Flex flex="1" justify="space-between">
              <Text>Gantt-Chart</Text>
              <GanttChartResize />
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <GanttChart />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
    settings: state.settings,
    isResetForm: state.project.isResetForm,
  }
}

export default connect(mapStateToProps)(SheetSummary)
