import { ROOT } from "../../const/globals"
import { copyDeep } from "../utils/objectCopyDeep"

/**
 * (c) Jasper Anders
 *
 * The function calculates recursively the position property
 * of a node and its child nodes.
 * @param {object} nodes
 * @param {*} nId
 * @param {array} position
 * @returns {object} nodes with updated positions
 */
export function nodesPositionsCalc(nodes, nId, position) {
  let node = nodes[nId]
  const { children } = node
  if (nId === ROOT) {
    // if node is root. it has no position
    nodes._root.position = []
  } else if (position) {
    // if no new position is provided, skip this part
    // generate new position array
    let positionNew = [...nodes[node.pId].position, position]
    nodes[node.nId].position = positionNew
  }
  if (!!children.length) {
    // repeat process for all children
    node.children.map((childId, index) =>
      nodesPositionsCalc(nodes, childId, index + 1)
    )
  }
  return nodes
}

/**
 * (c) Jasper Anders
 *
 * generate tree with updated tree positions
 * starting from an entry node. The entry Node
 * should always be the parent of a changed Node.
 * @param {object} nodes - nodes tree
 * @param {string} nIdEntry - node Id
 * @return {*} nodesNew - nodesNew with updated positions
 */
function nodesPositionGen(nodes, nIdEntry = ROOT) {
  // create copy of tree
  let nodesNew = copyDeep(nodes)
  // generate positions, initialize with position=null, as the starting nodes
  // position should not change
  nodesPositionsCalc(nodesNew, nIdEntry, null)
  return nodesNew
}

/**
 *
 */
export function positionToNId(nodes, position) {
  let nId = ""
  let node = nodes[ROOT]
  position.forEach((position) => {
    node = nodes[node.children[position - 1]]
    if (!!node) {
      nId = node.nId
    }
  })
  return nId
}

export default nodesPositionGen
