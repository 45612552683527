import { createSelector } from "reselect"
// import { hoursToDurStr } from "../../functions/durations/durations"
import { depsRefToPosStr } from "../../functions/nodes/nodesDependencies"
import {
  dateAddDuration,
  addOneHour,
} from "../../functions/timeHandler/timeHandler"
import { durToDurStr } from "../../functions/timeHandler/durations"
import { DAY_START, DAY_END } from "../../const/globals"
import parseISO from "date-fns/parseISO"
import isEqual from "date-fns/isEqual"
import isAfter from "date-fns/isAfter"
import isBefore from "date-fns/isBefore"

/**
 *
 * @param {*} state
 */
const getNodesCurrent = (state) =>
  state.project.dates[state.project.dateStatusCurrent].nodes

/**
 *
 * @param {*} state
 */
const getRowsCurrent = (state) =>
  state.project.dates[state.project.dateStatusCurrent].rows

/**
 *
 * @param {*} state
 */
const getDateStatusCurrent = (state) => state.project.dateStatusCurrent

/**
 *
 */
const getNodesInfos = createSelector(
  [getNodesCurrent, getRowsCurrent, getDateStatusCurrent],
  (nodesCurrent, rowsCurrent, dateStatusCurrent) => {
    let levelMax = Math.max(
      ...rowsCurrent.map((nId) => nodesCurrent[nId].position.length)
    )

    let nodesInfos = {}
    for (let [key] of Object.entries(nodesCurrent)) {
      const fromWhenDate = parseISO(nodesCurrent[key].fromWhen)
      const byWhenDate = parseISO(nodesCurrent[key].byWhen)

      let toProjectionDate = dateAddDuration(
        fromWhenDate,
        nodesCurrent[key].projection,
        DAY_START,
        DAY_END
      )

      let toProjectionISO = toProjectionDate.toISOString()

      let fromSlackISO = addOneHour(toProjectionISO, DAY_START, DAY_END)

      // toProjectionISO or fromSlackIOS cannot be after byWhen
      if (
        isEqual(toProjectionDate, byWhenDate) ||
        isAfter(toProjectionDate, byWhenDate)
      ) {
        toProjectionISO = nodesCurrent[key].byWhen
        // TODO: check logic
        // one day + 1 hour behind byWhen to move out of the way
        let fromSlackDate = dateAddDuration(
          toProjectionDate,
          { days: 1, hours: 1 },
          DAY_START,
          DAY_END
        )
        fromSlackISO = fromSlackDate.toISOString()
      }

      const spanStr = durToDurStr(nodesCurrent[key].span)
      const spentStr = durToDurStr(nodesCurrent[key].spent)
      const slackStr = durToDurStr(nodesCurrent[key].slack)
      const projectionStr = durToDurStr(nodesCurrent[key].projection)

      const isDeadlineDue = isBefore(
        parseISO(nodesCurrent[key].byWhen),
        parseISO(dateStatusCurrent)
      )

      const isDelivered = nodesCurrent[key].degree === "100"

      const isCommentRequired =
        nodesCurrent[key].children.length > 0
          ? false
          : (nodesCurrent[key].deadline > 0 &&
              nodesCurrent[key].deadline < 4) ||
            (nodesCurrent[key].forecast > 0 &&
              nodesCurrent[key].forecast < 4) ||
            (nodesCurrent[key].quality > 0 && nodesCurrent[key].quality < 4)

      nodesInfos[key] = {
        levelMax,
        precedentsStr: depsRefToPosStr(
          nodesCurrent,
          nodesCurrent[key].precedents
        ),
        spanStr,
        spentStr,
        toProjectionISO,
        projectionStr,
        fromSlackISO,
        slackStr,
        isDeadlineDue,
        isDelivered,
        isCommentRequired,
      }
    }
    return nodesInfos
  }
)

export default getNodesInfos
