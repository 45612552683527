import { copyDeep } from "../utils/objectCopyDeep"
import nodesList from "./nodesList"
import { positionToNId } from "./nodesPositionGen"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a precedentsStr string into an array of positions
 * @param {string} precedentsStr
 * @return {array} positions
 */
export function depsPosStrToPosArr(precedentsStr) {
  if (precedentsStr.trim() === "") {
    return []
  }

  // "01.03, 01.04"
  let positions = precedentsStr.split(",").filter((el) => !!el)
  // → ["01.03", "01.04"]

  positions = positions.map((posStr) => posStr.split("."))
  // → [["01", "03"], ["02","04"]]

  positions = positions.map((posArr) => posArr.map((el) => parseInt(el)))
  // → [[1, 3], [2, 4]]

  return positions
}

/**
 * (c) Jasper Anders
 * given an array of positions return an array of nIds at these positions
 * @param {object} nodes
 * @param {array} positions array of position arrays
 */
export function posArrToNIds(nodes, positions) {
  return [...positions.map((position) => positionToNId(nodes, position))]
}

/**
 * (c) Jasper Anders
 *
 * Transform a precedentsStr string into an array of nIds
 * @param {object} nodes
 * @param {string} precedentsStr
 * @return {array} array of nIds
 */
export function depsPosStrToNIds(nodes, nId, precedentsStr) {
  const positions = depsPosStrToPosArr(precedentsStr)
  // validate. Node can not be dependent on ([great]-grand) parent
  const validPositions = positions.filter((depPosition) => {
    // We have to find the shorter array to ensure we don't get out of bounds.
    const positionsLong =
      nodes[nId].position.length > depPosition.length
        ? nodes[nId].position
        : depPosition

    const positionsShort =
      nodes[nId].position.length <= depPosition.length
        ? nodes[nId].position
        : depPosition

    return !positionsShort.every((pos, index) => {
      if (pos !== positionsLong[index]) {
        return false
      }
      return true
    })
  })
  return posArrToNIds(nodes, validPositions)
}

/**
 * (c) Jasper Anders
 *
 * A function that sets both precedents of a node as well as the dependents of the parent.
 * @param {object} nodes
 * @param {string} nId
 * @param {array} precedentsStr a dep. string as it comes from the form
 */
export function nodesSetDependencies(nodes, nId, precedentsStr) {
  const nodesNew = copyDeep(nodes)
  const nIdPosArr = depsPosStrToNIds(nodesNew, nId, precedentsStr)
  const nIdPosArrClean = nIdPosArr.filter((el) => el.length > 0)
  nodesNew[nId].precedents = nIdPosArrClean
  nIdPosArrClean.forEach((precedentNId) => {
    nodesNew[precedentNId].dependents.push(nId)
  })
  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * Given a deps reference returns the positions of references
 * @param {object} nodes
 * @param {array} depsRef
 * @return {string} precedent positions
 */
export function depsRefToPosStr(nodes, depsRef) {
  if (!depsRef.length) {
    return ""
  }
  return depsRef
    .map((depRef) => {
      if (!nodes[depRef]) return ""
      return nodes[depRef].position
        .map((pos) => (pos.length > 1 ? `${pos}` : `0${pos}`))
        .join(".")
    })
    .join(", ")
}

/**
 * (c) Jasper Anders
 *
 * For all: Deletes all dependent from node.dependents if it is not
 * listed in the node.precedents from the dependent
 * OR: Clean dependent if not in precedents
 * @param {object} nodes
 * @return {object} nodesNew
 */
export function cleanDependentsAll(nodes) {
  const nodesNew = copyDeep(nodes)
  const allNodes = nodesList(nodes)

  allNodes.forEach((nId) => {
    // check every dependent in every node.dependents
    nodesNew[nId].dependents.forEach((dependent, index) => {
      // if this dependent is NOT in nodes.precedents then delete from node.dependents
      if (
        !nodesNew[dependent].precedents.some((precedent) => precedent === nId)
      ) {
        nodesNew[nId].dependents.splice(index, 1)
      }
    })
  })

  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * NOT NEEDED
 * For one nId: Deletes all dependent from node.dependents if it is not
 * listed in the node.precedents from the dependent
 * OR: Clean dependent if not in precedents
 * @param {object} nodes
 * @return {object} nodesNew
 */
export function cleanDependents(nodes, nId) {
  const nodesNew = copyDeep(nodes)

  nodesNew[nId].dependents.forEach((dependent, index) => {
    // if this dependent is NOT in nodes.precedents then delete from node.dependents
    if (
      !nodesNew[dependent].precedents.some((precedent) => precedent === nId)
    ) {
      nodesNew[nId].dependents.splice(index, 1)
    }
  })

  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * NOT NEEDED
 * For all: Delete precedent from node.precedents if it is not
 * listed in the node.dependents from the precedent
 * OR: Clean precedent if not in dependents
 * @param {object} nodes
 * @return {object} nodesNew
 */

export function cleanPrecedentsAll(nodes) {
  const nodesNew = copyDeep(nodes)
  const allNodes = nodesList(nodes)

  allNodes.forEach((nId) => {
    nodesNew[nId].precedents.forEach((precedent, index) => {
      if (
        !nodesNew[precedent].dependents.some((dependent) => dependent === nId)
      ) {
        nodesNew[nId].precedent.splice(index, 1)
      }
    })
  })

  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * NOT NEEDED
 * For one nId: Delete precedent from node.precedents if it is not
 * listed in the node.dependents from the precedent
 * OR: Clean precedent if not in dependents
 * @param {object} nodes
 * @return {object} nodesNew
 */

export function cleanPrecedents(nodes, nId) {
  const nodesNew = copyDeep(nodes)

  nodesNew[nId].precedents.forEach((precedent, index) => {
    if (
      !nodesNew[precedent].dependents.some((dependent) => dependent === nId)
    ) {
      nodesNew[nId].precedent.splice(index, 1)
    }
  })

  return nodesNew
}
