import React from "react"
import { connect } from "react-redux"
import CreatableSelect from "react-select/creatable"
import getPersonsOptions from "../../redux/reselect/getPersonsOptions"
import CustomClearIndicator from "./CustomClearIndicator"
import customStyles from "./style/customStyles"
import { selectMultiPersonsUpdate } from "../../redux/actions/project"

const SelectMultiPersons = ({
  name,
  value,
  personsOptions,
  isDisabled = false,
  isLocked = false,
  dispatch,
}) => {
  //=================================================================

  //-----------------------------------------------------------------
  const onChange = (data, actionMeta) => {
    // data.label has either the rId or - if new - the new name
    // select Id = sId has nId here

    let payload

    if (actionMeta.action === "clear") {
      payload = { name, selected: [], isNew: false }
    } else {
      payload = { name, selected: data }
    }

    dispatch(selectMultiPersonsUpdate(payload))
  }
  //=================================================================
  return (
    <CreatableSelect
      name="colors"
      value={value}
      onChange={onChange}
      options={personsOptions}
      styles={customStyles}
      isMulti
      isDisabled={isLocked}
      isLocked={isLocked}
      className="basic-multi-select"
      classNamePrefix="select"
      components={{
        ClearIndicator: CustomClearIndicator,
        DropdownIndicator: null,
        IndicatorSeparator: null,
        Placeholder: () => null,
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    personsOptions: getPersonsOptions(state),
  }
}

export default connect(mapStateToProps)(SelectMultiPersons)
