import { copyDeep } from "../utils/objectCopyDeep"
import isBefore from "date-fns/isBefore"
import max from "date-fns/max"
import min from "date-fns/min"
import parseISO from "date-fns/parseISO"
import {
  sumOfDuration,
  arrayMinElement,
  spentExtrapolate,
} from "../utils/mathHelper"
import { durToHours } from "../timeHandler/durations"
import {
  spanCalcFromByWhenFct,
  nodesTreeSpanSlackPipe,
} from "../timeHandler/timeHandler"
import { ROOT } from "../../const/globals"

/**
 * (c) Jasper Anders
 *
 * An abstract functions that allows accumulation over different vars
 * and applies a function.
 *
 * @param {object} nodes
 * @param {string} nId
 * @param {array} accumulateOverList array should contain strings of variable names
 * @param {function} accumulationFunction function should return nodes object
 * @param {array} nodeIgnoreMarker an array of strings that specifies a flag that shows if a node should be ignored, can be 'none' to never ignore
 * @param {array} usePinnedValue an array of strings that specifies a flag that shows if a node is pinned. If so all its child nodes are ignored.
 * @returns {object} nodesNew
 */
export function nodesAccumulate(
  nodes,
  nId,
  accumulateOverList,
  accumulationFunction,
  nodeIgnoreMarkers = [],
  usePinnedValue = []
) {
  const nodesNew = nodes
  // Extract properties of a node
  const node = nodes[nId]

  // check if any Ignore flag is true

  const { children } = node

  // accumulate for keys in list
  accumulateOverList.forEach((accumulateOver) => {
    // check if node has children
    const isPinnedValue = usePinnedValue
      .map((pinnedKey) => node[pinnedKey])
      .some((value) => value)

    // stop recursion if leaf
    if (!!children.length) {
      // accumulate over children
      const childValues = node.children.map((childNId) => {
        const isAnyIgnoreFlag = nodeIgnoreMarkers
          .map((marker) => nodes[childNId][marker])
          .some((value) => value)
        if (isAnyIgnoreFlag) {
          return null
        }
        const childNodesObject = nodesAccumulate(
          nodes,
          childNId,
          accumulateOverList,
          accumulationFunction,
          nodeIgnoreMarkers,
          usePinnedValue
        )
        return childNodesObject[childNId][accumulateOver]
      })
      const childValuesClean = childValues.filter((value) => value !== null)
      if (!isPinnedValue) {
        const accValue = accumulationFunction(childValuesClean)
        // console.log("nodesAccumulate: ", { nId, accValue, childValuesClean })
        // if accValue not defined, keep old value
        if (accValue !== undefined && accValue !== null) {
          nodesNew[nId][accumulateOver] = accValue
          // console.log("nodesAccumulate: ", { nId, accValue })
        }
      }
    }
  })
  // return the accumulated variable
  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * A function that generically applies a function to all nodes of a tree.
 * @param {object} nodes
 * @param {string} nId
 * @param {function} funcToApply funcToApply: function(nodes, node) returns nodes
 * @returns {object} nodesNew
 */
export function nodesApply(nodes, nId, funcToApply) {
  const nodesNew = nodes
  // Extract properties of a node
  const node = nodes[nId]
  const { children } = node
  // check if node has children
  if (!!children.length) {
    // accumulate over children, use the given function
    node.children.forEach((child) => {
      nodesNew[child] = nodesApply(nodesNew, child, funcToApply)[child]
    })
  }
  // apply function
  nodesNew[nId] = funcToApply(nodesNew, nId)[nId]

  return nodesNew
}

/**
 * (c) Jasper Anders
 * (c) Prof. Dr. Ulrich Anders
 *
 * A function that calculates the deadline for all nodes starting at nId relative to dId.
 * @param {nodes} nodes
 * @param {string} nId
 * @param {string} dId a dateId
 */
export function nodesDeadlineCalc(nodes, nId, dId) {
  const nodesNew = copyDeep(nodes)

  const deadlineCalc = (nodes, nId) => {
    const { byWhen, degree } = nodes[nId]

    const isByWhenBeforeStatus = isBefore(parseISO(byWhen), parseISO(dId))

    if (degree >= 100) {
      nodes[nId].deadline = 4 // green
      nodes[nId].forecast = 0
    }
    // < 100%
    else {
      if (isByWhenBeforeStatus) {
        nodes[nId].deadline = 1 // red
        nodes[nId].forecast = 0
        nodes[nId].quality = 0
      } else {
        nodes[nId].deadline = 0
        nodes[nId].quality = 0
      }
    }
    return nodes
  }

  return nodesApply(nodesNew, nId, deadlineCalc)
}

/**
 * (c) Jasper Anders
 *
 * A function that accumulates all status fields.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodesNew
 */
export function nodesStatusAcc(nodes) {
  const nId = ROOT
  return nodesAccumulate(nodes, nId, ["forecast", "quality"], arrayMinElement, [
    "isIgnored",
    "isUnresolved",
  ])
}

/**
 * (c) Jasper Anders
 *
 *
 * Accumulates the byWhen dates into the aggregation nodes
 * @param {object} nodes
 * @returns {object} nodes
 */

export function nodesDeadlineAcc(nodes) {
  const nId = ROOT
  return nodesAccumulate(nodes, nId, ["deadline"], arrayMinElement, [
    "isIgnored",
    "isUnresolved",
  ])
}

/**
 * (c) Jasper Anders
 *
 * Accumulates the byWhen dates into the aggregation nodes
 * @param {object} nodes
 * @returns {object} nodes
 */
export function byWhenAcc(nodes) {
  const nId = ROOT

  const getLatestDateString = (dates) => {
    const datesObj = dates.map((date) => parseISO(date))
    if (datesObj.length > 0) {
      return max(datesObj).toISOString()
    }
    return null
  }

  nodes = nodesAccumulate(
    nodes,
    nId,
    ["byWhen"],
    getLatestDateString,
    ["isIgnored", "isUnresolved"],
    ["isByWhenPinned"]
  )
  return nodes
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Accumulates the fromWhen dates into the aggregation nodes
 * @param {object} nodes
 * @returns {object} nodes
 */
export function fromWhenAcc(nodes) {
  const nId = ROOT
  let minDateISO

  const getEarliestDateString = (datesISO) => {
    const datesArr = datesISO.map((dateISO) => parseISO(dateISO))

    if (datesArr.length > 0) {
      minDateISO = min(datesArr).toISOString()
      return minDateISO
    }

    return null
  }

  nodes = nodesAccumulate(
    nodes,
    nId,
    ["fromWhen"],
    getEarliestDateString,
    ["isIgnored", "isUnresolved"],
    []
  )
  return nodes
}

/**
 * (c) Jasper Anders
 *
 * A function that accumulates all completion measures.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodesNew
 */
export function completionAccMeasures(nodes, nId) {
  return nodesAccumulate(nodes, nId, ["projection", "spent"], sumOfDuration, [
    "isUnresolved",
  ])
}

/**
 * (c) Jasper Anders
 * (c) Prof. Dr. Ulrich Anders
 *
 * A function that calculates all projection values.
 * @param {nodes} nodes
 * @param {string} nId
 */
export function nodesProjectionCalc(nodes, nId = ROOT) {
  let nodesNew = copyDeep(nodes)

  const projectionCalc = (nodes, nId) => {
    nodes[nId].projection = spentExtrapolate(
      nodes[nId].spent,
      nodes[nId].degree
    )
    // initially the projection is set to the span
    if (nodes[nId].projection.days === 0 && nodes[nId].projection.hours === 0) {
      nodes[nId].projection = spanCalcFromByWhenFct(
        nodes[nId].fromWhen,
        nodes[nId].byWhenFct
      )
    }
    if (nodes[nId].isIgnored) {
      nodes[nId].projection = nodes[nId].spent
    }
    return nodes
  }

  nodesNew = nodesApply(nodesNew, nId, projectionCalc)

  return nodesTreeSpanSlackPipe(nodesNew)
}

/**
 * (c) Jasper Anders
 * (c) Prof. Dr. Ulrich Anders
 *
 * A function that calculates degree of completion for all nodes.
 * @param {nodes} nodes
 * @param {string} nId
 */
export function nodesDegreeCalc(nodes, nId) {
  const nodesNew = copyDeep(nodes)

  const degreeCalc = (nodes, nId) => {
    const spentHours = durToHours(nodes[nId].spent)
    const projectionHours = durToHours(nodes[nId].projection)

    if (!!nodes[nId].children.length) {
      // calc node.degree only if some child degree > 0
      const isSomeChildrenDegree = nodes[nId].children.some(
        (child) => nodes[child].degree > 0
      )

      nodes[nId].degree =
        projectionHours && isSomeChildrenDegree > 0
          ? Math.round((spentHours / projectionHours) * 100)
          : 0
    }
    return nodesNew
  }
  return nodesApply(nodesNew, nId, degreeCalc)
}

/**
 * (c) Jasper Anders
 *
 * A function that wraps the accumulation of completion by first calculating
 * all leaf projections than accumulation all completion measures and finally
 * calculating the total degree of completion for all nodes.
 * @param {nodes} nodes
 * @param {string} nId
 */
export function completionAccPipe(nodes) {
  const nId = ROOT
  const nodesNew = copyDeep(nodes)
  const leafProjection = nodesProjectionCalc(nodesNew, nId)
  const completionAccumulate = completionAccMeasures(leafProjection, nId)
  return nodesDegreeCalc(completionAccumulate, nId)
}

/**
 * (c) Jasper Anders
 *
 * This is a pipeline function that calculates the following:
 *  - the traffic light value for each node, i.d. is the byWhen exceeded
 *  - accumulates the deadline to parent nodes via a minimizer function, i.d. exceeded
 *    deadlines propagate upwards through the tree
 * @param {*} nodes
 * @param {*} nId
 * @param {*} dId
 */
export function nodesDeadlinePipe(nodes, nId, dId) {
  const nodesNew = copyDeep(nodes)
  const nodesDeadlineCalculated = nodesDeadlineCalc(nodesNew, ROOT, dId)
  const nodesDeadlineAccumulated = nodesDeadlineAcc(nodesDeadlineCalculated)
  return nodesDeadlineAccumulated
}
