import React from "react"
import { chakra, Box } from "@chakra-ui/react"
import COLORS from "../../theme/colors"

const TrafficLight = ({
  colorCode,
  isIgnored = false,
  isDisabled = false,
  onClick,
  ...rest
}) => {
  const colors = [
    COLORS.white, // 0
    COLORS.red[500], // 1
    COLORS.orange[500], // 2
    COLORS.yellow[500], // 3
    COLORS.green[500], // 4
    COLORS.red[300],
    COLORS.orange[300],
    COLORS.yellow[300],
    COLORS.green[300],
  ]

  return (
    <Box {...rest}>
      <chakra.button
        type="button"
        onClick={isDisabled || isIgnored ? null : onClick}
        position="relative"
        top="2px"
        h="16px"
        w="16px"
        bg={
          isIgnored && colorCode !== 0
            ? colors[colorCode + 4]
            : colors[colorCode]
        }
        border="1px solid gray"
        borderRadius="50%"
        display="inline-block"
        cursor={onClick === undefined || isDisabled ? "default" : "pointer"}
        outline="none"
      ></chakra.button>
    </Box>
  )
}

export default TrafficLight
